import request from '@/utils/request'

// 查询地图图元列表
export function listPrimitive (query) {
  return request({
    url: '/iot/primitive/list',
    method: 'get',
    params: query
  })
}

// 查询地图图元详细
export function getPrimitive (id) {
  return request({
    url: '/iot/primitive/' + id,
    method: 'get'
  })
}

// 新增地图图元
export function addPrimitive (data) {
  return request({
    url: '/iot/primitive',
    method: 'post',
    data: data
  })
}

// 修改地图图元
export function updatePrimitive (data) {
  return request({
    url: '/iot/primitive',
    method: 'put',
    data: data
  })
}

// 删除地图图元
export function delPrimitive (id) {
  return request({
    url: '/iot/primitive/' + id,
    method: 'delete'
  })
}

// 导出地图图元
export function exportPrimitive (query) {
  return request({
    url: '/iot/primitive/export',
    method: 'get',
    params: query
  })
}
