<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图元编号" prop="identifier" v-if="formType === 1">
        <a-input v-model="form.identifier" placeholder="请输入图元编号" />
      </a-form-model-item>
      <a-form-model-item label="类型名称" prop="typeName" >
        <a-input v-model="form.typeName" placeholder="请输入类型名称" />
      </a-form-model-item>
      <a-form-model-item label="外形" prop="contour" >
        <a-select placeholder="请选择外形 " v-model="form.contour">
          <a-select-option v-for="(d, index) in contourOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属机构" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="详细信息" prop="infoDetail" >
        <a-input v-model="form.infoDetail" placeholder="请输入详细信息" />
      </a-form-model-item>
      <a-form-model-item label="是否删除 0否1是" prop="status" >
        <a-select placeholder="请选择是否删除 0否1是" v-model="form.status">
          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrimitive, addPrimitive, updatePrimitive } from '@/api/iot/primitive'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    contourOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data () {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // loading: false,
      formTitle: '',
      // 表单参数
      form: {
        identifier: null,
        typeName: null,
        contour: null,
        detpId: null,
        infoDetail: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        identifier: [
          { required: true, message: '图元编号不能为空', trigger: 'blur' }
        ],
        typeName: [
          { required: true, message: '类型名称不能为空', trigger: 'blur' }
        ],
        contour: [
          { required: true, message: '外形 不能为空', trigger: 'change' }
        ],
        status: [
          { required: true, message: '是否删除 0否1是不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    console.log(this.deptOptions)
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        identifier: null,
        typeName: null,
        contour: null,
        detpId: null,
        infoDetail: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      // this.$emit('select-tree')
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      // this.$emit('select-tree')
      this.formType = 2
      const id = row ? row.id : ids
      getPrimitive(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePrimitive(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addPrimitive(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
